import React, {useRef} from "react"
import MathJax from 'react-mathjax2'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"

const rel1 = "\vtext {drift(%)} = 1.0";
const rel2 = "\vtext {drift(%)} = 2.5";
const rel3 = "\vtext {drift(%)} = 5.0";
const rel4 = "\vtext {drift(%)} = 7.5";
const rel5 = "(P/(f_{ce}' A_{g})) <= 0.30";
const rel6 = "\vrho _{eff} >= 0.05";
const rel7 = "\vrho _{l} <= 0.04";
const rel8 = "s/d_{bl} <= 6";
const rel9 = "\vtext {cover}/D <= 0.1";
const rel10 = "D = 457,610mm";
const rel11 = "f_{yh} = 440-511 MPa";
const rel12 = "f_{y} = 469-486 MPa";
const rel13 = "f_{c}' = 36-54 MPa";
const rel14 = "d_{bl} = 19-25mm";
const rel15 = "\vtext {drift(%)} = 0.5";
const rel16 = "\vtext {drift(%)} = 0.8";
const rel17 = "\vtext {drift(%)} = 0.7";
const rel18 = "\vtext {drift(%)} = 1.0";
const rel19 = "\vtext {drift(%)} = 2.0";
const rel20 = "\vtext {drift(%)} = 4.0";
const rel21 = "\vtext {drift(%)} = 2.5";
const rel22 = "\vtext {drift(%)} = 5.0";
const rel23 = "\vtext {drift(%)} = 2.5";
const rel24 = "\vtext {drift(%)} = 5.0";
const rel25 = "\vtext {drift(%)} = 0.6";
const rel26 = "\vtext {drift(%)} = 2.0";
const rel26a = "\vtext {drift(%)} = 4.2";
const rel26b = "\vtext {drift(%)} = 8.0";
const rel27 = "\Delta_{bb} / L(%) = 0.9 - 3.13(P/(f_{ce}' A_{g})) + 142000 \vrho _{s}(f_{yhe} / E_{s})+0.45 L/D";
const rel28 = "\Delta_{bb} / L(%) = 3.25(1+150((\vrho _{eff} d_{bl})/D))(1-P/(f_{ce}' A_{g}))(1+0.10 L/D) ";
const rel28b = "\Delta_{ff} / L(%) = 16 + 0.71 \cdot L - 1.5 \cdot D - 3.8 \cdot 10^{-5} \cdot f_{c} \cdot (-2.8)10^{-5} \cdot f_{y} +1.7 \cdot \vrho_{l}+1.9 \cdot \vrho_{t}-12\cdot N/(A_{g} \cdot f_{c})";
const rel29 = "\Delta_{bb} / L(%) = 3.25(1+(k _{e} \vrho _{eff} d_{bl})/D)(1-P/(f_{ce}' A_{g}))(1+0.10 L/D) ";
const rel30 = "k_{e} = 150";
const rel31 = "\Delta_{spall} / L(%) = 1.6(1-P/(f_{ce}' A_{g}))(1+0.10 L/D) ";
const rel32 = "\vtext {drift(%)} = 0.11";
const rel33 = "\vtext {drift(%)} = 0.62";
const rel34 = "\vtext {drift(%)} = 2.91";
const rel35 = "\vtext {drift(%)} = 3.83";
const rel36 = "\vtext {drift(%)} = 3.8";
const rel37 = "\vtext {drift(%)} = 0.21";
const rel38 = "\vtext {drift(%)} = 0.46";
const rel39 = "\vtext {drift(%)} = 0.60";
const rel40 = "\vtext {drift(%)} = 0.99";
const rel41 = "\vtext {drift(%)} = 2.87";
const rel42 = "\vtext {drift(%)} = 2.75";
const rel43 = "\vtext {drift(%)} = 0.93";
const rel44 = "\vtext {drift(%)} = 1.43";
const rel45 = "\vtext {drift(%)} = 2.14";
const rel46 = "\vtext {drift(%)} = 1.5\Delta _{y} / L";
const rel47 = "\Delta_{spall} / L(%) = 1.6(1-P/(f_{ce}' A_{g}))(1+0.10 L/D)";
const rel48 = "\Delta_{bb} / L(%) = 0.8 \cdot 3.25(1.45 + 1.125 P/(f_{c}' A_{g}))(1-P/(f_{c}' A_{g}))(1+0.10 L _{col}/h _{col})";
const rel49 = "\vrho _{s} = 0.12(f _{c}' / f _{yh})(0.5+1.25(P/(f_{c}' A_{g}))) - \vtext {cylindrical section}";
const rel50 = "\Delta_{bb} / L(%) = 0.8 \cdot 3.25(1.15 + 0.375 P/(f_{c}' A_{g}))(1-P/(f_{c}' A_{g}))(1+0.10 L _{col}/h _{col})";
const rel51 = "\vrho _{s} = 1/3(0.12(f _{c}' / f _{yh})(0.5+1.25(P/(f_{c}' A_{g})))) - \vtext {cylindrical section}";
const rel62 = "\vmu_{d} = 0.1";
const rel63 = "\vmu_{d} = 0.6";
const rel64 = "\vmu_{d} = 2.82";
const rel65 = "\vmu_{d} = 4.15";
const rel66 = "\vmu_{d} = 3.50";
const rel67 = "\vmu_{d} = 0.3";
const rel68 = "\vmu_{d} = 0.8";
const rel69 = "\vmu_{d} = 1";
const rel70 = "\vmu_{d} = 1.7";
const rel71 = "\vmu_{d} = 4.8";
const rel72 = "D = 610mm";
const rel72b = "L = 5347mm";
const rel73 = "\rho_{l} = 0.010";
const rel74 = "f_{c}' = 24 MPa";
const rel75 = "f_{y} = 337 MPa";
const rel76 = "f_{yh} = 337 MPa";
const rel77 = "D = 457mm";
const rel78 = "L = 2440mm";
const rel79 = "(P/(f_{ce}' A_{g})) = 0.09";
const rel80 = "\rho_{l} = 0.012";
const rel81 = "A_{sw}/s = \Phi6/152.4mm";
const rel82 = "f_{c}' = 29-33 MPa";
const rel83 = "f_{y} = 355 MPa";
const rel84 = "f_{yh} = 576 MPa";
const rel85 = "\vtext {drift(%)} = 1.8, P/P_{0} = 0.54, \vrho_{s} = 1.12%";
const rel86 = "D = 356mm";
const rel87 = "L = 1473mm";
const rel88 = "P/(f_{ce}'A_{g}) = 0.27, 0.54";
const rel89 = "\vrho_{l} = 0.03";
const rel90 = "\vrho_{s} = 0.3, 1.12";
const rel91 = "f_{c}' = 40MPa";
const rel92 = "f_{y} = 400MPa";
const rel93 = "f_{yh} = 420MPa";
const rel94 = "\vtext {drift(%)} = 2.96, P/P_{0} = 0.27, \vrho_{s} = 1.12%";
const rel95 = "\vtext {drift(%)} = 1.8, P/P_{0} = 0.54, \vrho_{s} = 0.3%";
const rel96 = "\vtext {drift(%)} = 2.7, P/P_{0} = 0.27, \vrho_{s} = 0.3%";
const rel97 = "\vtext {drift(%)} = 2.5, P/P_{0} = 0.54, \vrho_{s} = 1.12%";
const rel98 = "\vtext {drift(%)} = 5.1, P/P_{0} = 0.27, \vrho_{s} = 1.12%";
const rel99 = "\vtext {drift(%)} = 2.0, P/P_{0} = 0.54, \vrho_{s} = 0.3%";
const rel100 = "\vtext {drift(%)} = 3.8, P/P_{0} = 0.27, \vrho_{s} = 0.3%";
const rel101 = "\vmu_{d} <= 2.0";
const rel102 = "\vmu_{d} <= 4.0";
const rel103 = "\vmu_{d} <= 7.0";
const rel104 = "\vmu_{d} > 7.0";
const rel105 = "\vmu_{d} = 1.2";
const rel106 = "\vmu_{d} = 1.76";
const rel107 = "\vmu_{d} = 4.76";
const rel108 = "D = 914mm";
const rel109 = "L = 5690mm";
const rel110 = "\rho_{l} = 0.012";
const rel111 = "A_{sw}/s = \Phi13/305mm";
const rel112 = "f_{c}' = 22.8 MPa";
const rel113 = "f_{y} = 276 MPa";
const rel114 = "f_{yh} = 276 MPa";



const LSExperimental = () => {
  //javascript
  const title = 'As Built Piers';
  const metatitle = 'As Built Piers';
  const description = 'Meta Description for As Built Piers';
  const metadescription = description;

  const scrollToRef = (ref) => window.scrollTo({
		left:0,
		top:ref.current.offsetTop,
		behavior: 'smooth'
	});
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  const Ref4 = useRef(null);
  const Ref5 = useRef(null);
  const Ref6 = useRef(null);
  const Ref7 = useRef(null);
  const Ref8 = useRef(null);
  const Ref9 = useRef(null);
  const Ref10 = useRef(null);
  const Ref11 = useRef(null);
  const Ref12 = useRef(null);
  const Ref13 = useRef(null);
  const Ref14 = useRef(null);
  const Ref15 = useRef(null);

	const executeScroll = (e, ref) => {
		e.preventDefault();
		scrollToRef(ref);
  }

  
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more actclass" href="../cylindrical/">Cylindrical Piers</a>
          </li>
          <li>
            <a className="more" href="../rectangular/">Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-rectangular/">Hollow Rectangular Piers</a>
          </li>
          <li>
            <a className="more" href="../hollow-circular/">Hollow Circular Piers</a>
          </li>
        </ul>
      </nav>
      <div className="content">
        <section className="exp-section">
          <h1>Cylindrical Piers</h1>
          <div className="content-table">
            <h2>Table 1: Cylindrical Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref1)}>[1]</button> ACI 341.4R-16 (2016)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel46}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Minor flexural cracks</td>
                  <td rowSpan={3}>Cyclic tests and field observations</td>
                  <td rowSpan={3}>Similar to Goodnight et al. (2016)</td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel47}</MathJax.Node>, Berry and Eberhard (2003)</div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling, yielding of longitudinal reinforcement</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel48}</MathJax.Node><br /><MathJax.Node inline>{rel49}</MathJax.Node><br /><MathJax.Node inline>{rel50}</MathJax.Node><br /><MathJax.Node inline>{rel51}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement or/and fracture of transverse reinforcement, partial crushing of concrete core</td>
                </tr>
                <tr className="even">
                  <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref2)}>[2]</button> 2.Banerjee, S. and Shinozuka, M. (2007)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel1}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Minor damage</td>
                  <td rowSpan={4}>Nonlinear Time- history analysis of bridge system</td>
                  <td rowSpan={4}>D = 2.4m<br />L = 21m</td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel2}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Moderate damage</td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel3}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Major damage</td>
                </tr>
                <tr className="even">
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel4}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Collapse</td>
                </tr>
                <tr>
                  <td rowSpan={2}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button> Berry, M. and Eberhard, M. (2003)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel29}</MathJax.Node><br /><MathJax.Node inline>{rel30}</MathJax.Node> for spiral reinforced columns</div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement</td>
                  <td rowSpan={2}>Cyclic loading</td>
                  <td rowSpan={2}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel31}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                </tr>
                <tr className="even">
                  <td><button onClick={ e => executeScroll(e, Ref4)}>[4]</button> Berry (2006)</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel28}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement</td>
                  <td>Cyclic loading</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><button onClick={ e => executeScroll(e, Ref5)}>[5]</button> Goodnight, C., Kowalski, M., Nau, J. (2016), circular columns</td>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel27}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement</td>
                  <td>Cyclic loading or real seismic load histories</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel5}</MathJax.Node><br /><MathJax.Node inline>{rel6}</MathJax.Node><br /><MathJax.Node inline>{rel7}</MathJax.Node><br /><MathJax.Node inline>{rel8}</MathJax.Node><br /><MathJax.Node inline>{rel9}</MathJax.Node><br /><MathJax.Node inline>{rel10}</MathJax.Node><br /><MathJax.Node inline>{rel11}</MathJax.Node><br /><MathJax.Node inline>{rel12}</MathJax.Node><br /><MathJax.Node inline>{rel13}</MathJax.Node><br /><MathJax.Node inline>{rel14}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref6)}>[6]</button> Kim, S. H. and Feng, M. Q. (2003)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel15}</MathJax.Node>, not-seismically designed<br /><MathJax.Node inline>{rel16}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>First yield of longitudinal reinforcement</td>
                  <td rowSpan={4}>-</td>
                  <td rowSpan={4}>-</td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel17}</MathJax.Node>, not-seismically designed<br /><MathJax.Node inline>{rel18}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>Concrete cracking, spalling</td>
                </tr>
                <tr className="even">
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel19}</MathJax.Node>, not-seismically designed<br /><MathJax.Node inline>{rel20}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>Initiation of column collapse</td>
                </tr>
                <tr className="even">
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel21}</MathJax.Node>, not-seismically designed<br /><MathJax.Node inline>{rel22}</MathJax.Node>, seismically designed</div>
                  </MathJax.Context></td>
                  <td>Column collapse</td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref7)}>[7]</button> Kwon, O. S., Elnashai, A. S. (2010)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel23}</MathJax.Node>, transverse direction<br /><MathJax.Node inline>{rel24}</MathJax.Node>, longitudinal direction</div>
                  </MathJax.Context></td>
                  <td>First yield of steel reinforcement</td>
                  <td rowSpan={3}>Pushover analysis of bridge system</td>
                  <td rowSpan={3}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel72}</MathJax.Node><br /><MathJax.Node inline>{rel72b}</MathJax.Node><br /><MathJax.Node inline>{rel73}</MathJax.Node><br /><MathJax.Node inline>{rel74}</MathJax.Node><br /><MathJax.Node inline>{rel75}</MathJax.Node><br /><MathJax.Node inline>{rel76}</MathJax.Node><br /></div>
                    </MathJax.Context></td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel25}</MathJax.Node>, transverse direction<br /><MathJax.Node inline>{rel26}</MathJax.Node>, longitudinal direction</div>
                  </MathJax.Context></td>
                  <td>Achievement of global maximum strength</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel26a}</MathJax.Node>, transverse direction<br /><MathJax.Node inline>{rel26b}</MathJax.Node>, longitudinal direction</div>
                  </MathJax.Context></td>
                  <td>Core concrete strain of 0.01</td>
                </tr>
                <tr className="even">
                  <td rowSpan={5}><button onClick={ e => executeScroll(e, Ref8)}>[8]</button> Lopez, A., Dusicka, P., Bazaez, R. (2020), 1970s design in USA</td>
                  <td rowSpan={2}>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel32}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cracking</td>
                  <td rowSpan={5}>Shake table</td>
                  <td rowSpan={5}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel77}</MathJax.Node><br /><MathJax.Node inline>{rel78}</MathJax.Node><br /><MathJax.Node inline>{rel79}</MathJax.Node><br /><MathJax.Node inline>{rel80}</MathJax.Node><br /><MathJax.Node inline>{rel81}</MathJax.Node><br /><MathJax.Node inline>{rel82}</MathJax.Node><br /><MathJax.Node inline>{rel83}</MathJax.Node><br /><MathJax.Node inline>{rel84}</MathJax.Node><br /></div>
                    </MathJax.Context>
                  </td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel33}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement</td>
                </tr>
                <tr className="even">
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel34}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                </tr>
                <tr className="even">
                  <td rowSpan={2}>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel35}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended spalling of concrete cover. </td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel36}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement or concrete core crushing</td>
                </tr>
                <tr>
                  <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref9)}>[9]</button> Mackie, K. R. and Stoiadinovic, B. (2007)</td>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel31}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                  <td rowSpan={3}>LS2 and LS3 were adopted from Berry and Eberhard (2003), LS4 was calculated based on experimental data, through regression</td>
                  <td rowSpan={3}>Similar to Berry and Eberhard (2003)</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel28}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement</td>
                </tr>
                <tr>
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel28b}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Collapse</td>
                </tr>
                <tr className="even">
                  <td rowSpan={8}><button onClick={ e => executeScroll(e, Ref10)}>[10]</button> Sheikh, S. A. and Yau, G. (2002)</td>
                  <td rowSpan={4}>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel85}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td rowSpan={4}>Spalling of concrete cover</td>
                  <td rowSpan={8}>Cyclic loading</td>
                  <td rowSpan={8}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel86}</MathJax.Node><br /><MathJax.Node inline>{rel87}</MathJax.Node><br /><MathJax.Node inline>{rel88}</MathJax.Node><br /><MathJax.Node inline>{rel89}</MathJax.Node><br /><MathJax.Node inline>{rel90}</MathJax.Node><br /><MathJax.Node inline>{rel91}</MathJax.Node><br /><MathJax.Node inline>{rel92}</MathJax.Node><br /><MathJax.Node inline>{rel93}</MathJax.Node><br /></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel94}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel95}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel96}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td rowSpan={4}>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel97}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td rowSpan={4}>Yielding of spiral/ bucking of long. bars</td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel98}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel99}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
                <tr className="even">
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel100}</MathJax.Node></div>
                  </MathJax.Context></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref1}>ACI Committee 341 (2016). ACI 341.4R-16 Report on the Seismic Design of Bridge Columns Based on Drift, ISBN: 978-1-945487-02-6. </li>
              <li ref={Ref2}>Banerjee, S. and Shinozuka, M. (2007). Nonlinear Static Procedure for Seismic Vulnerability Assessment of Bridges, Computer-Aided Civil and Infrastructure Engineering, Vol. 22, pp 293-305, <a target="_blank" href="https://doi.org/10.1111/j.1467-8667.2007.00486.x">https://doi.org/10.1111/j.1467-8667.2007.00486.x.</a></li>
              <li ref={Ref3}>Berry, M., Eberhard, M. (2003). Performance Models for Flexural Damage in Reinforced Concrete Columns, Report PEER 2003/18, Department of Civil and Environmental Engineering, University of Washington.</li>
              <li ref={Ref4}>Berry, M. P., (2006). Performance Modeling Strategies for Modern Reinforced Concrete Bridge Columns, PhD. Thesis, University of Washington, Seattle, WA.</li>
              <li ref={Ref5}>Goodnight, J. C., Kowalsy, M. J., Nau, J. M. (2016). Strain Limit States for Circular RC Bridge Columns, Earthquake Spectra, Vol. 32, No. 3., pp 1627-2652, <a target="_blank" href="https://doi.org/10.1193%2F030315EQS036M">doi.org/10.1193%2F030315EQS036M.</a></li>
              <li ref={Ref6}>Kim, S. H. and Feng, M. Q. (2003). Fragility analysis of bridges under ground motion with spatial variation, International Journal of Non-Linear Mechanics, Vol. 38, pp 705-721, <a target="_blank" href="https://doi.org/10.1016/S0020-7462(01)00128-7">https://doi.org/10.1016/S0020-7462(01)00128-7.</a> </li>
              <li ref={Ref7}>Kwon, O. S. and Elnashai, S. (2010). Fragility analysis of a highway over-crossing bridge with consideration of soil–structure interactions, Structure and Infrastructure Engineering, Vol. 6, Nos. 1-2, pp 159-178, <a target="_blank" href="https://doi.org/10.1080/15732470802663870">https://doi.org/10.1080/15732470802663870.</a></li>
              <li ref={Ref8}>Lopez, A., Dusicka, P., Bazaez, R. (2020). Performance of seismically substandard bridge reinforced concrete columns subjected to subduction and crustal earthquakes, Engineering Structures, <a target="_blank" href="https://doi.org/10.1016/j.engstruct.2020.110216">https://doi.org/10.1016/j.engstruct.2020.110216.</a></li>
              <li ref={Ref9}>Mackie, K. R., &amp; Stojadinović, B. (2007). R-Factor Parameterized Bridge Damage Fragility Curves. Journal of Bridge Engineering, ASCE, 12(4), 500-510, <a target="_blank" href="https://doi.org/10.1061/(ASCE)1084-0702(2007)12:4(500)">https://doi.org/10.1061/(ASCE)1084-0702(2007)12:4(500)</a></li>
              <li ref={Ref10}>Sheikh, S. A. and Yau, G. (2002). Seismic Behavior of Concrete Columns Confined with Steel and Fiber-Reinforced Polymer, ACI Structural Journal, Vol. 99, No. 1, pp 72 – 80, <a target="_blank" href="https://doi.org/10.14359/11037">https://doi.org/10.14359/11037</a>.</li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 2: Cylindrical Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility (μ<sub>d</sub>)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref11)}>[1]</button> Choi et al. (2004)</td>
                  <td>LS1</td>
                  <td><MathJax.Context input='ascii'>
                        <div><MathJax.Node inline>{rel101}</MathJax.Node></div>
                      </MathJax.Context></td>      
                  <td>Minor spalling </td>
                  <td rowSpan={4}>Adopted damage criteria for fragility analysis from soa</td>
                  <td rowSpan={4}>-</td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                        <div><MathJax.Node inline>{rel102}</MathJax.Node></div>
                      </MathJax.Context></td>      
                  <td>Moderate cracking and spalling</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                        <div><MathJax.Node inline>{rel103}</MathJax.Node></div>
                      </MathJax.Context></td>      
                  <td>Extensive damage</td>
                </tr>
                <tr>
                  <td>LS4</td>
                  <td><MathJax.Context input='ascii'>
                        <div><MathJax.Node inline>{rel104}</MathJax.Node></div>
                      </MathJax.Context></td>      
                  <td>Complete damage</td>
                </tr>
                <tr className="even">
                    <td rowSpan={5}><button onClick={ e => executeScroll(e, Ref12)}>[2]</button> Lopez, A., Dusicka, P., Bazaez, R. (2020), 1970s design in USA</td>
                    <td rowSpan={2}>LS1</td>
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel62}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Cracking of concrete</td>
                    <td rowSpan={5}>Shake table</td>
                    <td rowSpan={5}><MathJax.Context input='ascii'>
                        <div><MathJax.Node inline>{rel77}</MathJax.Node><br /><MathJax.Node inline>{rel78}</MathJax.Node><br /><MathJax.Node inline>{rel79}</MathJax.Node><br /><MathJax.Node inline>{rel80}</MathJax.Node><br /><MathJax.Node inline>{rel81}</MathJax.Node><br /><MathJax.Node inline>{rel82}</MathJax.Node><br /><MathJax.Node inline>{rel83}</MathJax.Node><br /><MathJax.Node inline>{rel84}</MathJax.Node><br /></div>
                        </MathJax.Context>
                      </td>
                  </tr>
                  <tr className="even">
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel63}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Yielding of longitudinal reinforcement</td>
                  </tr>
                  <tr className="even">
                    <td>LS2</td>
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel64}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Spalling of concrete cover</td>
                  </tr>
                  <tr className="even">
                    <td rowSpan={2}>LS3</td>
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel65}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Extended spalling of concrete cover</td>
                  </tr>
                  <tr className="even">
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel66}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Buckling of longitudinal reinforcement or crushing of concrete core</td>
                  </tr>
                  <tr>
                    <td rowSpan={3}><button onClick={ e => executeScroll(e, Ref13)}>[3]</button> Zhang, J. and Huo, Y. (2009)</td>
                    <td>LS1</td>
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel105}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Effective Yield</td>
                    <td rowSpan={3}>Adopted damage criteria for fragility analysis from soa</td>
                    <td rowSpan={3}>-</td>
                  </tr>
                  <tr>
                    <td>LS2</td>
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel106}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Spalling of concrete cover</td>
                  </tr>
                  <tr>
                    <td>LS3</td>
                    <td><MathJax.Context input='ascii'>
                      <div><MathJax.Node inline>{rel107}</MathJax.Node></div>
                    </MathJax.Context></td>
                    <td>Collapse</td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref11}>Choi, E., DesRoches, R., Nielson, B. (2004). Seismic fragility of typical bridges in moderate seismic zones, Engineering Structures, Vol. 26, pp 187-199, <a href="https://doi.org/10.1016/j.engstruct.2003.09.006" target="_blank">https://doi.org/10.1016/j.engstruct.2003.09.006.</a></li>
              <li ref={Ref12}>Lopez, A., Dusicka, P., Bazaez, R. (2020). Performance of seismically substandard bridge reinforced concrete columns subjected to subduction and crustal earthquakes, Engineering Structures, <a href="https://doi.org/10.1016/j.engstruct.2020.110216ank">https://doi.org/10.1016/j.engstruct.2020.110216.</a></li>
              <li ref={Ref13}>Zhang, J. and Huo, Y. (2009). Evaluating effectiveness and optimum design of isolation devices for highway bridges using the fragility function method, Engineering Structures, Vol. 31, pp 1648-1660, <a href="https://doi.org/10.1016/j.engstruct.2009.02.017" target="_blank">https://doi.org/10.1016/j.engstruct.2009.02.017.</a></li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 3: Bridge bents with cylindrical Piers: Limit state thresholds in terms of drift</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Drift (%)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={9}><button onClick={ e => executeScroll(e, Ref14)}>[1]</button> Bazaez,  R. and Dusicka, P., (2018), 1950-1970 design USA</td>
                  <td rowSpan={3}>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel37}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cracking</td>
                  <td rowSpan={9}>Cyclic loading</td>
                  <td rowSpan={9}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel108}</MathJax.Node><br /><MathJax.Node inline>{rel109}</MathJax.Node><br /><MathJax.Node inline>{rel110}</MathJax.Node><br /><MathJax.Node inline>{rel111}</MathJax.Node><br /><MathJax.Node inline>{rel112}</MathJax.Node><br /><MathJax.Node inline>{rel113}</MathJax.Node><br /><MathJax.Node inline>{rel114}</MathJax.Node><br /></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel38}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of the first longitudinal reinforcement</td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel39}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel40}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Concrete cover spalling</td>
                </tr>
                <tr>
                  <td>LS3/LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel41}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement  or crushing of concrete core</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel42}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement  followed by bar fracture</td>
                </tr>
                <tr>
                  <td>LS1-LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel43}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Cracks 1.5 mm wide (for design without EC8 )and 0.9 mm wide (EC8 compliant design)</td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel44}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Cracks of width > 1.5, concrete cover spalling</td>
                </tr>
                <tr>
                  <td>LS3</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel45}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Extended concrete spalling</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref14}>Bazaez, R. and Dusicka, P. (2018).  Performance assessment of multi-column RC bridge bents seismically retrofitted with buckling-restrained braces, Bulleting of Earthquake Engineering, Vol. 16, pp 2135-2160 <a href="https://doi.org/10.1007/s10518-017-0279-3" target="_blank">https://doi.org/10.1007/s10518-017-0279-3</a></li>
            </ol>
          </div>
        </section>
        <section className="exp-section">
          <div className="content-table">
            <h2>Table 4: Bridge bents with cylindrical Piers: Limit state thresholds in terms of displacement ductility</h2>
            <table>
              <thead>
                <tr>
                  <th colSpan={6}>Engineering Demand Parameter: Displacement ductility (μ<sub>d</sub>)</th>
                </tr>
                <tr>
                  <th>References</th>
                  <th>Limit States</th>
                  <th>Threshold Values</th>
                  <th>Description</th>
                  <th>Loading Type</th>
                  <th>Specimen Characteristics</th>
                </tr>
              </thead>
              <tbody>
              <tr>
                  <td rowSpan={5}><button onClick={ e => executeScroll(e, Ref15)}>[1]</button> Bazaez,  R. and Dusicka, P., (2018), 1950-1970 design USA</td>
                  <td rowSpan={3}>LS1</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel67}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Cracking of concrete</td>
                  <td rowSpan={9}>Cyclic loading</td>
                  <td rowSpan={9}><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel108}</MathJax.Node><br /><MathJax.Node inline>{rel109}</MathJax.Node><br /><MathJax.Node inline>{rel110}</MathJax.Node><br /><MathJax.Node inline>{rel111}</MathJax.Node><br /><MathJax.Node inline>{rel112}</MathJax.Node><br /><MathJax.Node inline>{rel113}</MathJax.Node><br /><MathJax.Node inline>{rel114}</MathJax.Node><br /></div>
                  </MathJax.Context></td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel68}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>First yielding of longitudinal reinforcement</td>
                </tr>
                <tr>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel69}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Yielding of longitudinal reinforcement</td>
                </tr>
                <tr>
                  <td>LS2</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel70}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Spalling of concrete cover</td>
                </tr>
                <tr>
                  <td>LS3/LS4</td>
                  <td><MathJax.Context input='ascii'>
                    <div><MathJax.Node inline>{rel71}</MathJax.Node></div>
                  </MathJax.Context></td>
                  <td>Buckling of longitudinal reinforcement  or crushing of concrete core</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="references">
            <h4>References</h4>
            <ol>
              <li ref={Ref15}>Bazaez, R. and Dusicka, P. (2018).  Performance assessment of multi-column RC bridge bents seismically retrofitted with buckling-restrained braces, Bulleting of Earthquake Engineering, Vol. 16, pp 2135-2160, <a href="https://doi.org/10.1007/s10518-017-0279-3" target="_blank">https://doi.org/10.1007/s10518-017-0279-3</a></li>
            </ol>
          </div>
        </section>
      </div>
      
    </Layout>
  )
}

export default LSExperimental
